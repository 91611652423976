import Header from "./components/Header/Header";
import './App.scss';
import Demo from "./components/Demo/Demo";
import Sponsors from "./components/Sponsors/Sponsors";
import Possibilieties from "./components/Possibilities/Possibilities";
import Slider from "./components/Slider/Slider";
import Accordiron from "./components/Accordeon/Accordiron";
import Form from "./components/Form/Form";
import Footer from "./components/Footer/Footer";

function App() {
    const accordionData = [
        {
            content: 'Підбір кандидатів на базі штучного інтелекту використовує розширені алгоритми для аналізу профілів кандидатів і вимог до роботи. Він оцінює навички, кваліфікацію та досвід, щоб визначити, хто найкраще підходить для конкретних ролей. Платформа мінімізує упередженість і забезпечує справедливий процес відбору, допомагаючи швидко й ефективно знаходити найбільш підходящих кандидатів.',
            title: 'Як працює підбір кандидатів на базі штучного інтелекту в Searchera?',
        },
        {
            content: 'Підбір кандидатів на базі штучного інтелекту використовує розширені алгоритми для аналізу профілів кандидатів і вимог до роботи. Він оцінює навички, кваліфікацію та досвід, щоб визначити, хто найкраще підходить для конкретних ролей. Платформа мінімізує упередженість і забезпечує справедливий процес відбору, допомагаючи швидко й ефективно знаходити найбільш підходящих кандидатів.',
            title: 'Чи може Searchera допомогти нам покращити різноманітність у нашому процесі найму?',
        },
        {
            content: 'Підбір кандидатів на базі штучного інтелекту використовує розширені алгоритми для аналізу профілів кандидатів і вимог до роботи. Він оцінює навички, кваліфікацію та досвід, щоб визначити, хто найкраще підходить для конкретних ролей. Платформа мінімізує упередженість і забезпечує справедливий процес відбору, допомагаючи швидко й ефективно знаходити найбільш підходящих кандидатів.',
            title: 'Як штучний інтелект залучає кандидатів протягом усього процесу найму?',
        },
        {
            content: 'Підбір кандидатів на базі штучного інтелекту використовує розширені алгоритми для аналізу профілів кандидатів і вимог до роботи. Він оцінює навички, кваліфікацію та досвід, щоб визначити, хто найкраще підходить для конкретних ролей. Платформа мінімізує упередженість і забезпечує справедливий процес відбору, допомагаючи швидко й ефективно знаходити найбільш підходящих кандидатів.',
            title: 'Чи може штучний інтелект надати інформацію про нашу робочу силу?',
        },
        {
            content: 'Підбір кандидатів на базі штучного інтелекту використовує розширені алгоритми для аналізу профілів кандидатів і вимог до роботи. Він оцінює навички, кваліфікацію та досвід, щоб визначити, хто найкраще підходить для конкретних ролей. Платформа мінімізує упередженість і забезпечує справедливий процес відбору, допомагаючи швидко й ефективно знаходити найбільш підходящих кандидатів.',
            title: 'Чи підходить Searchera для компаній будь-якого розміру?',
        }
    ]

    return (
        <div className="App">
            <Header/>
            <Demo/>
            <Sponsors />
            <Possibilieties />
            <Slider />
            <Accordiron accordionData={accordionData}/>
            <Form />
            <Footer />
        </div>
    );
}

export default App;
